import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { JsonViewer } from '@textea/json-viewer';
import React, { useEffect, useRef, useState } from 'react';
import { Grid, IconButton, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import Buttons from 'components/elements/buttons';
import TabPanel from 'components/elements/TabPanel';

import {
  BoxContentHeading,
  BoxContentItemChipList,
  BoxContentItemJobDesc,
  ParserDetailBox,
} from 'components/cards/ParserDetail-Items';
import { useDispatch, useSelector } from 'react-redux';
import { getJobParserDetail } from 'features/parser/jobParserAction';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { GeneratePdf } from 'utils/pdfExport';
import { ROUTE_PATHS } from 'data/paths';

export const ParserJobDetail = () => {
  const { job_parser_detail_data } = useSelector(state => state.jobParser);

  const dispatch = useDispatch();
  let { jobId } = useParams();
  const [tabValue, setTabValue] = useState('overview');

  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };
  const refContent = useRef();

  useEffect(() => {
    dispatch(getJobParserDetail({ id: jobId }));
  }, []);

  return (
    <ProtectedLayout>
      <div className="Page Page-ParserDetail">
        <div className="ParserDetail">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack text={'Back to Parser'} to={ROUTE_PATHS.PRODUCTS_PARSER_JOB} />
              </div>
            </div>
          </div>
          <div className="ParserDetail-Content">
            <div className="ParserDetail-Tabs">
              <Tabs className={'Tabs-Standart Tabs-ParserDetail'} value={tabValue} onChange={handleChangeTab}>
                <Tab label="Overview" value={'overview'} />
                <Tab label="JSON" value={'json'} />
              </Tabs>
              <div className="action">
                {tabValue === 'overview' ? (
                  <IconButton color="white" onClick={() => GeneratePdf(refContent, jobId)}>
                    <IconDownload />
                  </IconButton>
                ) : null}
              </div>
            </div>
            <div className="ParserDetail-Page">
              <TabPanel value={tabValue} index={'overview'}>
                {job_parser_detail_data && <ParserDetailList myRef={refContent} data={job_parser_detail_data} />}
              </TabPanel>

              <TabPanel value={tabValue} index={'json'}>
                <div className="json-viewer">
                  {job_parser_detail_data && <JsonViewer value={job_parser_detail_data} />}
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const ParserDetailList = ({ data, myRef }) => {
  const { response, description } = data;
  const { city, country, job_title, seniority, skills, enriched_skills, requirements } = response;
  // const { hard_skills, soft_skills } = enriched_skills;

  const showEnrichedSkills = enriched_skills && (
    (enriched_skills.hard_skills && enriched_skills.hard_skills.length > 0 && enriched_skills.hard_skills[0] !== null) || 
    (enriched_skills.soft_skills && enriched_skills.soft_skills.length > 0 && enriched_skills.soft_skills[0] !== null)
  );

  return (
    <div className="ParserDetail-List" ref={myRef}>
      <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '16px' }}>
        {(job_title || city || country) && (
          <Grid item sm={12}>
            <BoxContentHeading
              name={job_title}
              title={`${city && city}${city && country ? ',' : ''} ${country && country}`}
            />
          </Grid>
        )}


        {description && (
          <Grid item sm={12}>
            <ParserDetailBox title="JOB DESCRIPTION" label={`${seniority} level`}>
              <BoxContentItemJobDesc data={description && description} />
            </ParserDetailBox>
          </Grid>
        )}


{requirements && requirements.length > 0 && (
            <Grid item xs={12} md={6}>
              <ParserDetailBox 
                title="REQUIREMENTS" 
                label={`${requirements.length || 0} Skills (Requirements)`}
              >
                {[
                  {
                    title: 'Requirements',
                    list: requirements,
                    type: 'normal',
                  },
                ].map((item, index) => (
                  <BoxContentItemChipList
                    key={index}
                    title={item.title}
                    list={item.list}
                    type={item.type}
                  />
                ))}
              </ParserDetailBox>
            </Grid>
          )}


{showEnrichedSkills && (
          <Grid item xs={12} md={6}>
            <ParserDetailBox 
              title="ENRICHED SKILLS" 
              label={`${(enriched_skills?.hard_skills?.length || 0) + (enriched_skills?.soft_skills?.length || 0)} Skills`}
            >
              {enriched_skills?.hard_skills?.length > 0 && [
                {
                  title: 'Hard Skills',
                  list: enriched_skills?.hard_skills,
                  type: 'normal',
                },
              ].map((item, index) => {
                return (
                  <BoxContentItemChipList
                    key={index}
                    title={item.title}
                    list={item.list}
                    type={item.type}
                  />
                );
              })}

              {enriched_skills?.soft_skills?.length > 0 && [
                {
                  title: 'Soft Skills', 
                  list: enriched_skills?.soft_skills,
                  type: 'normal',
                },
              ].map((item, index) => {
                return (
                  <BoxContentItemChipList
                    key={index}
                    title={item.title}
                    list={item.list}
                    type={item.type}
                  />
                );
              })}
            </ParserDetailBox>
          </Grid>
        )}

        {skills && skills?.length > 0 && (
          <Grid item sm={12}>
            <ParserDetailBox title="QUALIFICATION" label={skills.length + ' Skills'}>
              {[
                {
                  title: 'Skills',
                  list: skills,
                  type: 'normal',
                },
              ].map((item, index) => {
                return <BoxContentItemChipList key={index} type={item.type} title={item.title} list={item.list} />;
              })}
            </ParserDetailBox>
          </Grid>
        )}

      </Grid>
    </div>
  );
};
